import { ApiError, Lastname, PhoneNumber } from '@mero/api-sdk';
import { PageId, WorkerPageRole } from '@mero/api-sdk/dist/pages';
import { SubscriptionId } from '@mero/api-sdk/dist/payments';
import { SubscriptionSeat } from '@mero/api-sdk/dist/payments/subscription-seat';
import {
  Avatar,
  AvoidKeyboard,
  Button,
  colors,
  Column,
  FormCard,
  H1,
  Header,
  Icon,
  Line,
  SmallBody,
  Spacer,
  useToast,
} from '@mero/components';
import { Firstname, StrictPhoneNumber } from '@mero/shared-sdk';
import * as ImagePicker from 'expo-image-picker';
import * as E from 'fp-ts/Either';
import { NonEmptyString } from 'io-ts-types';
import { isValidPhoneNumber } from 'libphonenumber-js';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, ScrollView, TouchableOpacity } from 'react-native';

import DisabledSelect from '../../../../../components/DisabledSelect';
import MenuItem from '../../../../../components/MenuItem';
import ModalScreenContainer from '../../../../../components/ModalScreenContainer';
import TypeSafeTextInputWithDescription from '../../../../../components/TypeSafeTextInputWithDescription';
import InputWithLabel from '@mero/components/lib/components/InputWithLabel';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';
import TypeSafeTextInput from '@mero/components/lib/components/TypeSafeTextInput';
import { useKeyboardIsOpen } from '@mero/components/lib/hooks';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';
import usePromise from '../../../../../hooks/usePromise';

import { AddNewProContext } from '../../../../../contexts/AddNewProContext';
import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import { ProsSettingsAddNewStackParamList } from '../../../../../types';
import log from '../../../../../utils/log';
import { styles } from './AddNewProScreen.styles';
import AddSeatDialog from './AddSeatDialog';
import { EditIcon } from './ProProfileDetailsScreen';
import ProfilePhotoMenu from './ProfilePhotoMenu';

export type Props = StackScreenProps<ProsSettingsAddNewStackParamList, 'ProAddNew'>;

const INPUT_POSITIONS = {
  firstname: 50,
  lastname: 120,
  phone: 220,
  email: 300,
} as const;

const AddNewProScreen: React.FC<Props> = ({ navigation }) => {
  const { t } = useTranslation('pros');
  const { isPhone } = useMediaQueries();

  const goBack = useGoBack();
  const isKeyboardOpen = useKeyboardIsOpen();
  const { promise, reject, resolve } = usePromise();
  const toast = useToast();

  const [pageState, { reloadAsync }] = CurrentBusinessContext.useContext();
  const [{ details, services, calendar, businessHours, role }, { updateDetails, updateRole }] =
    AddNewProContext.useContext();

  const block = React.useRef(false);
  const scrollRef = React.useRef<ScrollView>(null);

  const [showErrors, setShowErrors] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showPhotoMenu, setShowPhotoMenu] = React.useState(false);
  const [addProInfo, setAddProInfo] = React.useState<
    (SubscriptionSeat & { expiresAt: Date; subscriptionId: SubscriptionId }) | null
  >(null);
  const [addNewSeat, setAddNewSeat] = React.useState(false);
  const [showAddSeatDialog, setShowAddSeatDialog] = React.useState(false);

  const [scrollToY, setScrollToY] = React.useState<number | undefined>(undefined);

  const [profilePhoto, setProfilePhoto] = React.useState(details.profilePhoto);

  const [firstname, setFirstname] = React.useState({
    input: details.firstname,
    decoded: NonEmptyString.decode(details.firstname ?? ''),
  });
  const firstnameValid = E.isRight(firstname.decoded);

  const [lastname, setLastname] = React.useState({
    input: details.lastname,
    decoded: NonEmptyString.decode(details.lastname),
  });
  const lastnameValid = E.isRight(lastname.decoded);

  const [phone, setPhone] = React.useState({
    input: details.phone,
    decoded: PhoneNumber.decode(details.phone),
  });
  const phoneValid = E.isRight(phone.decoded) && isValidPhoneNumber(details.phone, 'RO');

  // const [email, setEmail] = React.useState({
  //   input: details.email,
  //   decoded: Email.decode(details.email),
  // });
  // const emailValid = E.isRight(email.decoded);

  const [invitePro, setInvitePro] = React.useState(details.invitePro);

  React.useEffect(() => {
    try {
      if (isKeyboardOpen && scrollToY !== undefined) {
        setScrollToY(undefined);
        scrollRef?.current?.scrollTo({ y: scrollToY, animated: true });
      }
    } catch (e: unknown) {
      log.exception(e);
    }
  }, [scrollToY, isKeyboardOpen, setScrollToY, scrollRef, scrollRef?.current]);

  const toggleInvitePro = () => {
    setInvitePro(!invitePro);
  };

  const scrollTo = (inputName: keyof typeof INPUT_POSITIONS): void => {
    setScrollToY(INPUT_POSITIONS[inputName]);
  };

  const togglePhotoMenu = () => {
    setShowPhotoMenu(!showPhotoMenu);
  };

  const addPhoto = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
    });

    if (!result.canceled) {
      setProfilePhoto(result.assets[0].uri);
    }
  };

  const deletePhoto = () => {
    setProfilePhoto('');
  };

  const checkPageSeats = async (pageId: PageId) => {
    if (pageState.type !== 'Loaded') {
      return;
    }
    try {
      const page = pageState.page;
      const subscription = await meroApi.payments.getPageSubscription(pageId);
      log.debug('Info seats', {
        seatsAvailable: subscription?.seatCount ?? 'none',
        seatsUsed: pageState.page.workers.length,
      });

      // pages that are still in trial don't have subscription.seatCount defined
      // so avoid showing the pro-rata modal
      if (subscription && !!subscription.seatCount && subscription.seatCount <= pageState.page.workers.length) {
        const seatInfo = await meroApi.payments.getSubscriptionSeatUpdateInfo({
          subscriptionId: subscription._id,
          newSeats: 1,
        });

        setAddProInfo({
          ...seatInfo.result,
          expiresAt: subscription.expires,
          subscriptionId: subscription._id,
        });
      }
    } catch (error) {
      log.error('Failed to check page seats', error);
      return undefined;
    }
  };

  const cancelAddNewSeat = () => {
    setShowAddSeatDialog(false);
    reject('addNewSeat');
    goBack();
  };

  const confirmAddNewSeat = () => {
    setAddNewSeat(true);
    setShowAddSeatDialog(false);
    resolve('addNewSeat');
  };

  const canAddSeat = async () => {
    try {
      if (addNewSeat || addProInfo === null || details.addNewSeat) {
        return true;
      }

      if (!addProInfo.shouldConfirmNewPrice) {
        await meroApi.payments.updateSubscriptionSeatCount({
          subscriptionId: addProInfo.subscriptionId,
          newSeats: 1,
        });

        return true;
      }

      await promise('addNewSeat', () => {
        setShowAddSeatDialog(true);
      });

      return true;
    } catch {
      return false;
    }
  };

  const saveChanges = async () => {
    if (pageState.type !== 'Loaded') {
      return;
    }

    if (!firstnameValid || !lastnameValid || !phoneValid) {
      setShowErrors(true);
      return;
    }

    setIsLoading(true);
    try {
      const newUserId = await meroApi.pages.addPageMember({
        pageId: pageState.page.details._id,
        firstname: firstname.input as Firstname,
        lastname: lastname.input as Lastname,
        phone: phone.input as StrictPhoneNumber,
        roles: role ? [role.id] : [],
      });

      if (calendar.isActive) {
        const workerId = await meroApi.pages.createPageMemberWorker({
          pageId: pageState.page.details._id,
          userId: newUserId,
          services,
        });

        const proDetails = await meroApi.pages.getPageWorker({ pageId: pageState.page.details._id, workerId });

        await Promise.all([
          meroApi.calendar.updateSettings({
            pageId: pageState.page.details._id,
            calendarId: proDetails.calendar._id,
            newSettings: {
              ...proDetails.calendar.settings,
              isPrivate: calendar.isPrivate,
              calendarInterval: {
                ...proDetails.calendar.settings.calendarInterval,
                value: calendar.calendarInterval,
              },
              maxAppointmentsPerClient: calendar.maxAppointmentsPerClient,
              minHoursBeforeClientsCanCancelAppointments: calendar.minHoursBeforeClientsCanCancelAppointments,
              minHoursBeforeTodayAppointments: calendar.minHoursBeforeTodayAppointments,
              maxWaitingListDaysPerClient: calendar.maxWaitingListDaysPerClient,
            },
          }),
          meroApi.pages.updateWorkerBusinessHours({
            pageId: pageState.page.details._id,
            workerId: proDetails._id,
            businessHours,
          }),
        ]);
      }

      await reloadAsync();

      toast.show({
        type: 'success',
        text: t('savedSuccessfully'),
      });

      goBack();
    } catch (error) {
      log.error('Error creating worker', error);
      if (error instanceof ApiError) {
        toast.show({
          type: 'error',
          text: error.message,
        });
      } else {
        toast.show({
          type: 'error',
          text: t('errorDetails'),
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const goToSelectService = async () => {
    if (pageState.type !== 'Loaded') {
      return;
    }

    if (!firstnameValid || !lastnameValid || !phoneValid) {
      setShowErrors(true);
      return;
    }

    if (pageState.page.members.some((m) => m.user.phone?.includes(phone.input))) {
      toast.show({
        type: 'error',
        text: t('phoneAlreadyExists'),
      });

      return;
    }

    if (!(await canAddSeat())) {
      return;
    }

    navigation.navigate('ProAddNewServicesSelect');
  };

  React.useEffect(() => {
    updateDetails({
      firstname: firstname.input,
      lastname: lastname.input,
      phone: phone.input,
      // email: email.input,
      invitePro,
      profilePhoto,
      addProInfo,
      addNewSeat,
    });
  }, [firstname.input, lastname.input, phone.input, /*email.input,*/ invitePro, profilePhoto, addProInfo, addNewSeat]);

  React.useEffect(() => {
    if (pageState.type === 'Loaded') {
      checkPageSeats(pageState.page.details._id);
      if (!role) {
        updateRole(pageState.page.roles.find((r) => r.id === WorkerPageRole.value) ?? pageState.page.roles[0]);
      }
    }
  }, [pageState.type]);

  const isWorker = role?.isWorkerRole || calendar.isActive;

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <Header
        LeftComponent={() => (
          <TouchableOpacity onPress={goBack} style={{ paddingLeft: 16 }}>
            <Icon type="back" />
          </TouchableOpacity>
        )}
        text={t('addPro')}
      />
      <AvoidKeyboard style={{ flex: 1 }}>
        <ScrollView ref={scrollRef}>
          <Column style={{ paddingHorizontal: 16, paddingTop: 16, flex: 1, paddingBottom: 96 }}>
            <H1 style={{ paddingHorizontal: 8 }}>{t('addPro')}</H1>
            <Spacer size={32} />
            <FormCard dropShaddow rounded paddings="inputs">
              <Column style={{ alignItems: 'center' }}>
                <TouchableOpacity onPress={togglePhotoMenu}>
                  <Avatar
                    size={60}
                    source={profilePhoto}
                    firstname={firstname.input ?? ''}
                    lastname={lastname.input ?? ''}
                  />
                  <Column style={styles.editIcon}>
                    <EditIcon />
                  </Column>
                </TouchableOpacity>
                <Spacer size={12} />
                <SmallBody style={{ color: colors.COMET, textAlign: 'center' }}>{t('editPhotoDetail')}</SmallBody>
              </Column>
              <Spacer size="24" />
              <InputWithLabel
                label={t('firstName')}
                isError={showErrors && !firstnameValid}
                errorText={t('firstNameError')}
              >
                <TypeSafeTextInput
                  codec={NonEmptyString}
                  value={firstname.input}
                  showError={showErrors}
                  onChange={setFirstname}
                  placeholder={t('firstNamePlaceholder')}
                  onFocus={() => scrollTo('firstname')}
                />
              </InputWithLabel>
              <Spacer size="16" />
              <InputWithLabel
                label={t('lastName')}
                isError={showErrors && !lastnameValid}
                errorText={t('lastNameError')}
              >
                <TypeSafeTextInput
                  codec={NonEmptyString}
                  value={lastname.input}
                  showError={showErrors}
                  onChange={setLastname}
                  placeholder={t('lastNamePlaceholder')}
                  onFocus={() => scrollTo('lastname')}
                />
              </InputWithLabel>
              <Spacer size="16" />
              <InputWithLabel label={t('phone')} isError={showErrors && !phoneValid} errorText={t('phoneError')}>
                <TypeSafeTextInputWithDescription
                  description={t('phoneNotVisible')}
                  isError={showErrors && !phoneValid}
                  codec={PhoneNumber}
                  value={phone.input}
                  showError={showErrors}
                  onChange={setPhone}
                  placeholder={t('phonePlaceholder')}
                  onFocus={() => scrollTo('phone')}
                  keyboardType={'phone-pad'}
                />
              </InputWithLabel>
              {/*<Spacer size="16" />*/}
              {/*<InputWithLabel label={t('email')} isError={showErrors && !emailValid} errorText={t('emailError')}>*/}
              {/*  <TypeSafeTextInput*/}
              {/*    codec={Email}*/}
              {/*    value={email.input}*/}
              {/*    showError={showErrors}*/}
              {/*    onChange={setEmail}*/}
              {/*    placeholder={t('emailPlaceholder')}*/}
              {/*    onFocus={() => scrollTo('email')}*/}
              {/*    keyboardType={'email-address'}*/}
              {/*  />*/}
              {/*</InputWithLabel>*/}
              {/*<Spacer size="16" />*/}
              {/*<Row style={{ paddingTop: 8 }}>*/}
              {/*  <Column>*/}
              {/*    <Checkbox value={invitePro} color="blue" disabled={false} onValueChange={toggleInvitePro} />*/}
              {/*  </Column>*/}
              {/*  <TouchableOpacity style={{ paddingLeft: 12, flex: 1 }} onPress={toggleInvitePro}>*/}
              {/*    <Title>{t('inviteProTitle')}</Title>*/}
              {/*    <SmallBody style={{ color: colors.COMET, paddingTop: 4 }}>{t('inviteProDescription')}</SmallBody>*/}
              {/*  </TouchableOpacity>*/}
              {/*</Row>*/}
              {pageState.type === 'Loaded' && pageState.allPages.length > 1 ? (
                <>
                  <Spacer size="16" />
                  <InputWithLabel label={t('location')}>
                    <DisabledSelect label={pageState.page.details.name} />
                  </InputWithLabel>
                </>
              ) : null}
            </FormCard>
            <Spacer size="16" />
            <FormCard dropShaddow rounded paddings="button">
              <MenuItem
                disabled={!(pageState.type === 'Loaded' && pageState.page.permissions.account.canManagePermissions())}
                title={t('permissions')}
                icon="pro-menu-permissions"
                subtitle={role?.name ?? ''}
                onPress={() => navigation.navigate('ProAddNewPermissions')}
              />
              <Line />
              <MenuItem
                title={t('calendarSettings')}
                icon="pro-menu-calendar-settings"
                onPress={() => navigation.navigate('ProAddNewCalendar')}
              />
              {isWorker && (
                <>
                  {/*<Line />*/}
                  {/*<MenuItem*/}
                  {/*  title={t('servicesCustom')}*/}
                  {/*  //@ts-expect-error pluralization is not supported*/}
                  {/*  subtitle={t('activeServices', { count: services.length })}*/}
                  {/*  subtitleColor={services.length > 0 ? colors.COMET : colors.RADICAL_RED}*/}
                  {/*  icon="pro-menu-services"*/}
                  {/*  onPress={() => navigation.navigate('ProAddNewServices')}*/}
                  {/*/>*/}
                  <Line />
                  <MenuItem
                    title={t('schedule')}
                    icon="pro-menu-pending-clients"
                    onPress={() => navigation.navigate('ProAddNewSchedule')}
                  />
                </>
              )}
            </FormCard>
          </Column>
        </ScrollView>
      </AvoidKeyboard>

      {(!showPhotoMenu && !showAddSeatDialog) || Platform.OS !== 'android' ? (
        <FormCard
          dropShaddow
          paddings="button"
          style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
        >
          <SafeAreaView edges={['bottom']}>
            {isPhone ? (
              <Button
                disabled={block.current || isLoading}
                text={isWorker ? t('continue') : t('saveChanges')}
                onClick={isWorker ? goToSelectService : saveChanges}
              />
            ) : (
              <Button
                disabled={block.current || isLoading}
                expand={false}
                containerStyle={{ alignSelf: 'center' }}
                text={isWorker ? t('continue') : t('saveChanges')}
                onClick={isWorker ? goToSelectService : saveChanges}
              />
            )}
          </SafeAreaView>
        </FormCard>
      ) : null}

      {showPhotoMenu && (
        <ProfilePhotoMenu onDismiss={togglePhotoMenu} onAddPhoto={addPhoto} onDeletePhoto={deletePhoto} />
      )}

      {showAddSeatDialog && addProInfo ? (
        <AddSeatDialog
          onSuccess={confirmAddNewSeat}
          onCancel={cancelAddNewSeat}
          addonPrice={addProInfo.addonPrice}
          totalPrice={addProInfo.proRataPrice}
          expiresAt={addProInfo.expiresAt}
          subscriptionId={addProInfo.subscriptionId}
        />
      ) : null}
    </ModalScreenContainer>
  );
};

export default AddNewProScreen;
