import { StrictPhoneNumberParsed, Worker } from '@mero/api-sdk';
import { PageMemberPreview, RootPageRole } from '@mero/api-sdk/dist/pages';
import { SavedWorker } from '@mero/api-sdk/dist/workers';
import {
  Avatar,
  Button,
  colors,
  Column,
  FormCard,
  H1,
  Header,
  Icon,
  Row,
  SmallBody,
  Spacer,
  Text,
  useToast,
} from '@mero/components';
import * as ImagePicker from 'expo-image-picker';
import * as E from 'fp-ts/Either';
import { NonEmptyString } from 'io-ts-types';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Platform, ScrollView, TouchableOpacity } from 'react-native';
import Svg, { G, Path } from 'react-native-svg';

import DisabledSelect from '../../../../components/DisabledSelect';
import ModalScreenContainer from '../../../../components/ModalScreenContainer';
import InputWithLabel from '@mero/components/lib/components/InputWithLabel';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';
import Body from '@mero/components/lib/components/Text/Body';
import TypeSafeTextInput from '@mero/components/lib/components/TypeSafeTextInput';
import { useKeyboardIsOpen } from '@mero/components/lib/hooks';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../hooks/useMediaQueries';

import { meroApi } from '../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../contexts/CurrentBusiness';
import { IntercomContext } from '../../../../contexts/IntercomContext';
import { AuthorizedStackParamList, MenuStackParamList } from '../../../../types';
import log from '../../../../utils/log';
import InvitedProWarning from './ProsDashboardScreen/InvitedProWarning';
import ProfilePhotoMenu from './ProsDashboardScreen/ProfilePhotoMenu';
import { styles } from './WorkerProfileSettingsScreen.styles';

export const EditIcon = () => (
  <Svg width={10} height={9.997}>
    <G fill="#52577f">
      <Path d="m1.061 6.437 4.712-4.712 2.491 2.491-4.712 4.712ZM9.619 2.9A1.628 1.628 0 0 0 9.398.6 1.89 1.89 0 0 0 8.075 0a1.465 1.465 0 0 0-.977.378Z" />
      <Path data-name="layer1" d="M.718 6.984 0 9.996l3.012-.7Zm5.491-5.692.464-.464 2.5 2.5-.464.464Z" />
    </G>
  </Svg>
);

const INPUT_POSITIONS = {
  firstname: 50,
  lastname: 150,
  phone: 250,
} as const;

export type Props = StackScreenProps<AuthorizedStackParamList & MenuStackParamList, 'WorkerProfileSettingsScreen'>;

const WorkerProfileSettingsScreen: React.FC<Props> = ({ route, navigation }) => {
  const isKeyboardOpen = useKeyboardIsOpen();
  const goBack = useGoBack();
  const { t } = useTranslation('pros');
  const { isPhone } = useMediaQueries();
  const toast = useToast();
  const isFocused = navigation.isFocused();

  const { userId, workerId } = route.params;

  const [pageState, { reloadAsync }] = CurrentBusinessContext.useContext();
  const [, { openChat }] = IntercomContext.useContext();

  const scrollRef = React.useRef<ScrollView>(null);
  const block = React.useRef(false);

  const [memberDetails, setMemberDetails] = React.useState<PageMemberPreview | null>(null);
  const [workerDetails, setWorkerDetails] = React.useState<SavedWorker | null>(null);
  const [showErrors, setShowErrors] = React.useState(false);
  const [scrollToY, setScrollToY] = React.useState<number | undefined>(undefined);
  const [showPhotoMenu, setShowPhotoMenu] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isAdmin, setIsAdmin] = React.useState(false);

  const [profilePhoto, setProfilePhoto] = React.useState<string | undefined>(
    memberDetails?.user.profile.photo?.thumbnail,
  );

  const [firstname, setFirstname] = React.useState({
    input: memberDetails?.user.profile.firstname ?? '',
    decoded: NonEmptyString.decode(memberDetails?.user.profile.firstname ?? ''),
  });
  const firstnameValid = E.isRight(firstname.decoded);

  const [lastname, setLastname] = React.useState({
    input: memberDetails?.user.profile.lastname ?? '',
    decoded: NonEmptyString.decode(memberDetails?.user.profile.lastname ?? ''),
  });
  const lastnameValid = E.isRight(lastname.decoded);

  const [phone, setPhone] = React.useState({
    input: memberDetails?.user.phone ?? '',
    decoded: StrictPhoneNumberParsed.decode(memberDetails?.user.phone ?? ''),
  });
  const phoneValid = E.isRight(phone.decoded);

  React.useEffect(() => {
    if (pageState.type === 'Loaded') {
      const member = pageState.page.members.find((m) => m.user._id === userId);
      const worker = pageState.page.workers.find((w) => w._id === workerId);

      setMemberDetails(member ?? null);
      setWorkerDetails(worker ?? null);

      if (member) {
        const isAdmin = member.roles.some((r) => r.id === RootPageRole.value);
        setFirstname({
          input: member.user.profile.firstname ?? '',
          decoded: NonEmptyString.decode(member.user.profile.firstname),
        });
        setLastname({
          input: member.user.profile.lastname ?? '',
          decoded: NonEmptyString.decode(member.user.profile.lastname),
        });
        setPhone({
          input: member.user.phone?.includes('null') ? '' : member.user.phone ?? '',
          decoded: StrictPhoneNumberParsed.decode(member.user.phone?.includes('null') ? '' : member.user.phone ?? ''),
        });
        setIsAdmin(isAdmin);

        setProfilePhoto(member.user.profile.photo?.thumbnail);
      } else if (worker) {
        setFirstname({
          input: worker.user.firstname ?? '',
          decoded: NonEmptyString.decode(worker.user.firstname),
        });
        setLastname({
          input: worker.user.lastname ?? '',
          decoded: NonEmptyString.decode(worker.user.lastname),
        });
        setPhone({
          input: worker.user.phone.includes('null') ? '' : worker.user.phone ?? '',
          decoded: StrictPhoneNumberParsed.decode(worker.user.phone.includes('null') ? '' : worker.user.phone ?? ''),
        });
        setIsAdmin(false);

        setProfilePhoto(worker?.profilePhoto?.thumbnail);
      }
    }
  }, [pageState]);

  React.useEffect(() => {
    if (isFocused) {
      reloadAsync();
    }
  }, [isFocused]);

  React.useEffect(() => {
    try {
      if (isKeyboardOpen && scrollToY !== undefined) {
        setScrollToY(undefined);
        scrollRef?.current?.scrollTo({ y: scrollToY, animated: true });
      }
    } catch (e: unknown) {
      log.exception(e);
    }
  }, [scrollToY, isKeyboardOpen, setScrollToY, scrollRef, scrollRef?.current]);

  const scrollTo = (inputName: keyof typeof INPUT_POSITIONS): void => {
    setScrollToY(INPUT_POSITIONS[inputName]);
  };

  const togglePhotoMenu = () => {
    setShowPhotoMenu(!showPhotoMenu);
  };

  const addPhoto = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
    });

    if (!result.canceled) {
      setProfilePhoto(result.assets[0].uri);
    }
  };

  const deletePhoto = () => {
    setProfilePhoto(undefined);
  };

  const onContactUsPressed = React.useCallback(() => {
    openChat();
  }, [openChat]);

  const saveChanges = async () => {
    const details = memberDetails
      ? ({ type: 'member', user: memberDetails } as const)
      : workerDetails
      ? ({ type: 'worker', user: workerDetails } as const)
      : null;

    if (pageState.type !== 'Loaded' || !details) {
      return;
    }

    if (!firstnameValid || !lastnameValid || !phoneValid) {
      setShowErrors(true);
      return;
    }

    setIsLoading(true);
    try {
      const { _id: newUserId } = await meroApi.pages.updatePageMemberProfileInfo({
        pageId: pageState.page.details._id,
        userId: details.user.user._id,
        firstname: firstname.input,
        lastname: lastname.input,
        phone: undefined,
      });

      const promises: Promise<unknown>[] = [];

      const existingPhoto =
        details.type === 'member' ? details.user.user.profile.photo?.thumbnail : details.user.profilePhoto?.thumbnail;

      if (profilePhoto && profilePhoto !== existingPhoto) {
        const profilePhotoUri = Platform.OS === 'ios' ? profilePhoto.replace('file://', '') : profilePhoto;
        const response = await fetch(profilePhotoUri);
        const blob = await response.blob();

        promises.push(
          meroApi.pages.updatePageMemberProfilePhoto({
            pageId: pageState.page.details._id,
            userId: details.user.user._id,
            profileImage: { platform: Platform.OS, blob, uri: profilePhotoUri },
          }),
        );
      }

      if (profilePhoto === undefined) {
        promises.push(
          meroApi.pages.deletePageMemberProfilePhoto({
            pageId: pageState.page.details._id,
            userId: details.user.user._id,
          }),
        );
      }

      await Promise.all(promises);

      reloadAsync();

      toast.show({
        type: 'success',
        text: t('savedSuccessfully'),
      });

      goBack();
    } catch (error) {
      log.error('Failed to update worker details', error);
      toast.show({
        type: 'error',
        text: t('errorDetails'),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const isEditable = React.useMemo(
    () =>
      workerDetails && pageState.type === 'Loaded' ? !Worker.isInvited(workerDetails, pageState.page.details) : true,
    [workerDetails, pageState],
  );

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <Header
        LeftComponent={() => (
          <TouchableOpacity onPress={goBack} style={{ paddingLeft: 16 }}>
            <Icon type="back" />
          </TouchableOpacity>
        )}
        text={t('profileDetails')}
        RightComponent={() => (
          <Column style={{ paddingRight: 24 }}>
            <Avatar
              size={32}
              source={memberDetails?.user.profile.photo?.thumbnail}
              firstname={memberDetails?.user.profile.firstname ?? ''}
              lastname={memberDetails?.user.profile.lastname ?? ''}
            />
          </Column>
        )}
      />
      {(memberDetails || workerDetails) && (
        <ScrollView ref={scrollRef}>
          <Column style={{ paddingHorizontal: 16, paddingTop: 16, flex: 1 }}>
            <H1 style={{ paddingHorizontal: 8 }}>{t('profileDetails')}</H1>
            <Spacer size={24} />
            {!isEditable && (
              <>
                <Spacer size={8} />
                <InvitedProWarning message={t('invitedProWarning2')} />
                <Spacer size={16} />
              </>
            )}
            <FormCard rounded>
              <Column style={{ alignItems: 'center' }}>
                <TouchableOpacity onPress={togglePhotoMenu} disabled={!isEditable}>
                  <Avatar
                    size={60}
                    source={profilePhoto}
                    firstname={memberDetails?.user.profile.firstname ?? workerDetails?.user.firstname ?? ''}
                    lastname={memberDetails?.user.profile.lastname ?? workerDetails?.user.lastname ?? ''}
                  />
                  {isEditable && (
                    <Column style={styles.editIcon}>
                      <EditIcon />
                    </Column>
                  )}
                </TouchableOpacity>
                <Spacer size={12} />
                <SmallBody style={{ color: colors.COMET, textAlign: 'center' }}>{t('editPhotoDetail')}</SmallBody>
              </Column>
              <Spacer size="24" />
              <InputWithLabel
                label={t('firstName')}
                isError={showErrors && !firstnameValid}
                errorText={t('firstNameError')}
              >
                <TypeSafeTextInput
                  codec={NonEmptyString}
                  value={firstname.input}
                  showError={showErrors}
                  onChange={setFirstname}
                  placeholder={t('firstNamePlaceholder')}
                  onFocus={() => scrollTo('firstname')}
                  editable={isEditable}
                />
              </InputWithLabel>
              <Spacer size="16" />
              <InputWithLabel
                label={t('lastName')}
                isError={showErrors && !lastnameValid}
                errorText={t('lastNameError')}
              >
                <TypeSafeTextInput
                  codec={NonEmptyString}
                  value={lastname.input}
                  showError={showErrors}
                  onChange={setLastname}
                  placeholder={t('lastNamePlaceholder')}
                  onFocus={() => scrollTo('lastname')}
                  editable={isEditable}
                />
              </InputWithLabel>
              <Spacer size="16" />
              <InputWithLabel label={t('phone')} isError={showErrors && !phoneValid} errorText={t('phoneError')}>
                <Row style={{ alignItems: 'center' }}>
                  <Body style={{ flex: 1 }}>{phone.input}</Body>
                  {isEditable && (
                    <Button
                      expand={false}
                      size="small"
                      text="Modifică"
                      backgroundColor="transparent"
                      disabledBackgroundColor="transparent"
                      color={colors.DARK_BLUE}
                      onClick={() => navigation.navigate('ChangePhone', { screen: 'ChangePhoneNumber' })}
                    />
                  )}
                </Row>
              </InputWithLabel>
              {/* Not release yet on prod */}
              {/* <Spacer size="16" />
              <InputWithLabel label={t('location')}>
                <DisabledSelect label={pageState.type === 'Loaded' ? pageState.page.details.name : ''} />
              </InputWithLabel> */}
            </FormCard>
          </Column>
          <Spacer size="16" />
        </ScrollView>
      )}
      <FormCard
        dropShaddow
        paddings="button"
        style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
      >
        <SafeAreaView edges={['bottom']}>
          {isPhone ? (
            <Button
              disabled={block.current || isLoading || !isEditable}
              text={memberDetails ? t('saveChanges') : t('save')}
              onClick={saveChanges}
            />
          ) : (
            <Button
              disabled={block.current || isLoading || !isEditable}
              expand={false}
              containerStyle={{ alignSelf: 'center' }}
              text={memberDetails ? t('saveChanges') : t('save')}
              onClick={saveChanges}
            />
          )}
        </SafeAreaView>
      </FormCard>
      {showPhotoMenu && (
        <ProfilePhotoMenu onDismiss={togglePhotoMenu} onAddPhoto={addPhoto} onDeletePhoto={deletePhoto} />
      )}
    </ModalScreenContainer>
  );
};

export default WorkerProfileSettingsScreen;
